'use client';

import {AlertProvider, ThemeProvider} from '@kakaoent/ops-design';
import {
  QueryClient,
  QueryClientProvider as QueryClientProvider5,
} from '@tanstack/react-query';
import {AppProgressBar as ProgressBar} from 'next-nprogress-bar';
import {ReactNode, useRef} from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {RecoilRoot} from 'recoil';
import '../locales';
import {instQueryClient as instQueryClient5} from '../api/_queryClient5';
import {BrowserFilter} from '../components/molecules';
import {getEnv} from '../utils/getEnv';

const Providers = function ({children}: {children?: ReactNode}) {
  // query client setting
  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = instQueryClient5();
  }

  const reCaptchaKey = getEnv('RECAPTCHA_SITE_KEY') ?? '';
  return (
    <QueryClientProvider5 client={queryClientRef.current}>
      <ThemeProvider themeColor="gray" theme={{}}>
        <AlertProvider>
          <RecoilRoot>
            <BrowserFilter>
              <GoogleReCaptchaProvider
                reCaptchaKey={reCaptchaKey}
                scriptProps={{
                  async: false, // optional, default to false,
                  defer: false, // optional, default to false
                  appendTo: 'head', // optional, default to "head", can be "head" or "body",
                  nonce: undefined, // optional, default undefined
                }}
                container={{
                  parameters: {
                    badge: 'bottomright', //"[inline|bottomright|bottomleft]"
                  },
                }}
              >
                {children}
                <ProgressBar
                  height="4px"
                  color="#2299DD"
                  options={{showSpinner: false}}
                  shallowRouting
                />
              </GoogleReCaptchaProvider>
            </BrowserFilter>
          </RecoilRoot>
        </AlertProvider>
      </ThemeProvider>
    </QueryClientProvider5>
  );
};

export default Providers;
