import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import translationEn from './translation.en.json';
import translationKo from './translation.ko.json';

i18next.use(initReactI18next).init({
  lng: 'ko',
  fallbackLng: 'ko',
  // debug: process.env.NODE_ENV === "development",
  debug: false,
  resources: {
    ko: {
      translation: translationKo,
    },
    en: {
      translation: translationEn,
    },
  },
});

export default i18next;
